<template>
  <b-card-code title="Add Auction Transaction Match">

    <div class="row">
      <div class="col-md-6">
        <label for="">Buyer</label>
        <v-select
          v-model="formPayload.buyer_id"
          :options="buyers"
          :reduce="option => option.id"
          @search="onSearchBuyer">
          <template slot="no-options">
            Type user name, code, or email..
          </template>
        </v-select>
      </div>
      <div class="col-md-6">
        <label for="">Seller</label>
        <v-select
          v-model="formPayload.seller_id"
          :options="sellers"
          :reduce="option => option.id"
          @search="onSearchSeller">
          <template slot="no-options">
            Type user name, code, or email..
          </template>
        </v-select>
      </div>

    </div>

    <div class="row mt-1">

      <div class="col-md-6">
        <label for="">Auction</label>
        <v-select v-model="formPayload.auction_uuid" :options="auctionOptions" :reduce="(option) => option.auctionUuid" label="auctionLabel"></v-select>
      </div>

      <div class="col-md-6">

          <label for="">Match Price</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">USD</span>
            </div>
            <input type="text" class="form-control" v-model="formPayload.price">
          </div>

      </div>

    </div>

    <div class="row mt-1">
      <div class="col-md-12">
        <div class="form-group">
          <label for="">Transaction Time</label><br>
            <flat-pickr
              v-model="formPayload.time"
              class="form-control"
              placeholder="Select date"
              :config="{ dateFormat: 'Y-m-d H:i', enableTime: true}"
            />
        </div>
      </div>
    </div>

    <div class="row mt-1">

      <div class="col-6">
        
            <label for="">Bid Lot</label>
            <div class="input-group">
              <input type="text" class="form-control" v-model="formPayload.bid_lot">
              <div class="input-group-append">
                <span class="input-group-text">lot</span>
              </div>
            </div>

      </div>

      <div class="col-6">
        

            <label for="">Ask Lot</label>
            <div class="input-group">
              <input type="text" class="form-control" v-model="formPayload.ask_lot">
              <div class="input-group-append">
                <span class="input-group-text">lot</span>
              </div>
            </div>

      </div>
      
    </div>

    <div class="row mt-1">
      <div class="col-md-12">
        <button class="btn btn-success" @click="submitOrder()" v-bind:disabled="isLoading">Submit Order</button>
      </div>
    </div>

  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import vSelect from 'vue-select'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'

export default {
  title () {
    return `Add Auction Transaction`
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  components: {
    BCard,
    BButton,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardCode,
    BSpinner,
    vSelect,
    flatPickr
  },
  data() {
    return {
      formPayload: {
        auction_uuid: '',
        buyer_id: '',
        seller_id: '',
        time: '',
        price: 1,
        bid_lot: 1,
        ask_lot: 1,
      },
      buyers: [],
      sellers: [],
      auctions: [],
      auctionOptions: [],
      isLoading: false
    }
  },
  created() {
    this.loadAuction();
    this.executeSearchBuyer('')
    this.executeSearchSeller('')
  },
  methods: {
    submitOrder() {

      this.isLoading = true;
      this.formPayload.time += ':00'
       this.$http.post('/admin/auction-transactions/manual-order', this.formPayload)
        .then(response => {
  
          successNotification(this, 'Success', 'Transaction successfully created')
  
          this.isLoading = false;
  
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)   
          }
          this.isLoading = false;
        })

    },
    loadAuction() {
      
      var queryParams = {}
      queryParams.per_page = 100;
      queryParams.start_at_daterange = moment().subtract(1, 'days').format('DD/MM/YYYY');
      queryParams.start_at_daterange += '-' + moment().add(1, 'years').format('DD/MM/YYYY');
      this.formPayload.time = moment().format('YYYY-MM-DD HH:mm')

      this.$http.get('/admin/auction', {
          params: queryParams
      })
      .then(response => {
        var context = this
        context.auctions = response.data.data.data
        context.auctions.forEach(function(auction){
          context.auctionOptions.push({
            auctionUuid: auction.uuid,
            auctionLabel: auction.commodity.code + '-' + auction.warehouse.name + ' (' + auction.date + ')'
          });
        })
        context.auctionOptions.sort(function(obj1, obj2){
            if ( obj1.auctionLabel < obj2.auctionLabel ){
              return -1;
            }
            if ( obj1.auctionLabel > obj2.auctionLabel ){
              return 1;
            }
            return 0;
        })
      })

    },
    executeSearchBuyer(query){
      var vm = this
      this.$http.get('/admin/users?user_type=normal&per_page=200&sort_by_register=desc&keyword=' + encodeURI(query))
      .then(response => {
        vm.buyers = [];
        response.data.data.data.forEach(function(user){
          vm.buyers.push({
            'id': user.id,
            'label': user.code + ' (' + user.name + ')'
          })
        });

      })
    },
    onSearchBuyer(search, loading) {
      if(search.length) {
        loading(true);
        this.searchBuyer(loading, search, this);
      }
    },
    searchBuyer: _.debounce((loading, search, vm) => {
      vm.executeSearchBuyer(encodeURI(search))
      loading(false);
    }, 300),
    executeSearchSeller(query){
      var vm = this
      this.$http.get('/admin/users?user_type=normal&per_page=200&sort_by_register=desc&keyword=' + encodeURI(query), { useCache: true })
      .then(response => {
        vm.sellers = [];
        response.data.data.data.forEach(function(user){
          vm.sellers.push({
            'id': user.id,
            'label': user.code + ' (' + user.name + ')'
          })
        });

      })
    },
    onSearchSeller(search, loading) {
      if(search.length) {
        loading(true);
        this.searchSeller(loading, search, this);
      }
    },
    searchSeller: _.debounce((loading, search, vm) => {
      vm.executeSearchSeller(encodeURI(search))
      loading(false);
    }, 300),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>